<template>
    <div>
      <div class="nav" style="margin-bottom: 10px;">
          <ul>
            <li>
              <div class="nav_c " :class="{nav_c_type:navtype==1}" @click="onnav(1)">
                  {{ $t("stake.Stake") }}
              </div>
  
            </li>
            <li>
              <div class="nav_c" :class="{nav_c_type:navtype==2}" @click="onnav(2)">
                  {{  $t("Withdraw.Withdraw") }}
              </div>
            </li>
  
          </ul>
        </div>
    <div class="stakewait">
      <!-- notification start -->
      <div class="marquee">
        <Marquee :duration="15" v-if="falconAddresstype">{{ promptvalue }}</Marquee>
      </div>
  
      <!-- notification end -->
      <div class="coinInfomation">
        <div class="coinImg"><img :src="falocn" class="dailogo"/></div>
        <div class="coinInfo">Falcon DAI plan</div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div v-if="navtype==1">
  
      <div class="ownwalletInfo">
        <div class="ownTips">USDT:</div>
        <div class="ownMoney">
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <div class="inputInfo">
        <!-- @input="validateAmount" -->
        <input v-model.number="daiValue" type="number" class="inputStyle"
               style="width: 58.66667vw;"/>
        <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">FALCON:</div>
        <div class="ownMoney">
          {{ formattedFalconbalance }}<img class="swap_falcon" :src="D2"/>
        </div>
      </div>
      <div class="inputInfo">
        <input v-model.number="daiValue2" type="number" class="inputStyle" style="width: 58.66667vw;"/>
        <button @click="(daiValue2 = formattedFalconbalance)" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>
      
  
      <div class="StakeAuthor">
        <div class="Author" v-if="empowerbutton == false">
          <!-- appoove button start-->
          <button :disabled="empowerbutton" class="StakeBtn ripple" @click="empower" v-if="typeApprove == 0">
            {{ $t("stake.Appoove") }} USDT
          </button>
          <button class="StakeBtn" v-if="typeApprove == 1">
            {{ $t("stake.Appooveing") }}
          </button>
          <!-- appoove button end -->
        </div>
        <div class="Author" v-else-if="Falconall == false">
          <!-- appoove button start-->
          <button :disabled="Falconall" class="StakeBtn ripple" @click="empowerfalcon" v-if="typeApprove == 0">
            {{ $t("stake.Appoove") }} Falcon
          </button>
          <button class="StakeBtn" v-if="typeApprove == 1">
            {{ $t("stake.Appooveing") }}
          </button>
          <!-- appoove button end -->
        </div>
  
    
        <div class="Stakeing" >
          <button
            v-if="empowerbutton == true && Falconall == true && daiValue != '' || daiValue2 != ''  "
            class="StakeBtn ripple" @click="pickDeposit">
            {{ $t("stake.Stake") }}
          </button>
         
          <button disabled v-else class="StakeBtn ripple" >
            {{ $t("stake.Stake") }}
          </button>
  
        </div>
      </div>
    </div>
    <div v-if="navtype==2">
      <div class="ownwalletInfo">
        <div class="ownTips">USDT:</div>
        <div class="ownMoney">
          {{ usdtAmount| fildecimals }}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
  
      <div class="inputInfo">
          <!-- @input="validateAmount" -->
        <input v-model.number="falvalue1" type="number" class="inputStyle"
               style="width: 58.66667vw;"/>
        <button @click="(falvalue1 = Math.floor(usdtAmount) )" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>
     
      <div class="ownwalletInfo">
        <div class="ownTips">FALCON:</div>
        <div class="ownMoney">
          {{ pickGetMyUserFalconvalue | fildecimals }}<img class="swap_falcon" :src="D2"/>
        </div>
      </div>
      <div class="inputInfo">
        <input v-model.number="falvalue2" type="number" class="inputStyle" style="width: 58.66667vw;"/>
        <button @click="(falvalue2 = Math.floor(pickGetMyUserFalconvalue))" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>
      <div class="ownwalletInfo">
      <div class="ownTips">Profit:</div>
      <div class="ownMoney">
        {{ pickGetMyUserDaiInterestvalue | fildecimals }}<img class="daimi" :src="swap_usdt"/>
      </div>
    </div>
    <div class="inputInfo">
      <input v-model.number="falvalue3" type="number" class="inputStyle" style="width: 58.66667vw;"/>
      <button @click="(falvalue3 =  Math.floor(pickGetMyUserDaiInterestvalue))" class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button>
    </div>
     
  <div class="time_c" v-if="lastcalltimevaluetype==false">
    {{countdown}}
  </div>
      <div class="WithDrawBtn" >
          <!-- @click="withdraw" -->
        
          <button class="tbnStyle2 ripple" @click="pickWithdraw()"
          style="width: 100%;"
                  v-if=" falvalue1 != '' || falvalue3 != '' || falvalue2 !='' && lastcalltimevaluetype">
            {{ $t("Withdraw.Withdraw") }}
          </button>
          <button class="tbnStyle1 ripple" disabled v-else  style="width: 100%;">
            {{ $t("Withdraw.Withdraw") }}
          </button>
          <!-- <button class="tbnStyle2 ripple" @click="withdrawOne('lq')"
                  v-if="empowerbutton == true && Falconall == true && falvalue4 != ''">
            {{ $t("Withdraw.Getreward") }}
          </button>
          <button class="tbnStyle1 ripple" disabled v-else>
            {{ $t("Withdraw.Getreward") }}
          </button> -->
  
        </div>
      
  
   </div>
      <div><img class="bStyle" :src="stylea" alt=""/></div>
      <!--loading start-->
      <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
      <!--loading start-->
      <div v-if="isloading2" class="loading">
        <div class="tips">正在寻找套利机会</div>
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
    </div>
  </div>
  </template>
  <style scoped src="@/assets/css/EvFalocn2pro.css"></style>
  <style lang="less" scoped>
  .time_c{
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
  }
 
.ownwalletInfo {
  font-family: "Poppins", sans-serif;
  display: flex;
}
.ownwalletInfo .ownTips {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.ownwalletInfo .ownMoney {
  font-size: 20px;
}
.ownwalletInfo .ownMoney img.daimi {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.ownwalletInfo .ownMoney img.swap_falcon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

  </style>
  <script>
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import ProgressBar from "@/components/ProgressBar.vue";
  import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import D1 from "@/assets/img/d1.svg";
  import D2 from "@/assets/img/d2.svg";
  import D3 from "@/assets/img/d3.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import FG1 from "@/assets/img/FG1.svg";
  import falocn from "@/assets/img/FalconLogoNew.svg";
  import daimni from "@/assets/img/daimini.svg";
  import swap_falcon from "@/assets/img/swap_falcon.svg";
  import swap_matic from "@/assets/img/swap_matic.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import Falocnn from "@/plugins/Falocnn.js";
  import Falcon2js from "@/plugins/Falcon2js.js";
  import Web3 from "web3";
  import axios from "axios";
  import sign from "@/plugins/sign.js";
  
  export default {
    data() {
      return {
        swap_usdt,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typedeposit: 0,
        dai: dai,
        FG1: FG1,
        falocn: falocn,
        daimni: daimni,
        swap_falcon: swap_falcon,
        swap_matic: swap_matic,
        loadingimg: loadingimg,
        daiValue: 0,
        daiValue2: 0,
        daiValue3: 0,
        D1: D1,
        D2: D2,
        D3: D3,
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        currentProgress: 0,
        usersdata: {},
        sitedata: {},
        addresscode: "",
        empowerbutton: true,
        needinvest: "",
        Minimal: 0,
        isloading: false,
        isloading2: false,
        falconAddresstype: false,
        isOpentype: false,
        flashAddresstype: true,
        returndatadata: "",
        promptvalue: "",
        honorLeave: "",
        Falconall: false,
        Falconbalance: 0,
        getMaticBalancevalue: 0,
        falconuserdata: {
          daiAmount: 0,
          falconAmount: 0
        },
        maticvalue: 0,
        getMyUserFalconvalue: 0,
        interestvalue: 0,
        Falconv2pro: "",
        isV2: false,
        navtype:1,
        falvalue1:0,
        falvalue2:0,
        falvalue3:0,
        falvalue4:0,
        balanceusdt:0,
        usdtAmount:0,
        falconAmount:0,
        pickGetMyUserFalconvalue:0,
        pickGetMyUserDaiInterestvalue:0,
        endTime: null,
        countdown: '',
        lastcalltimevaluetype:false
      };
    },
    components: {
      Marquee,
      //ProgressBar,
    },
    async mounted() {
  
      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");
  
        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error(
              "Failed to obtain network information. Procedure:",
              error
            );
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        // this.daiOwnMoney = parseFloat(
        //   await web3Utils.getDaiBalance(this.addresscode)
        // );
  
      
  
        this.signtype();
        // this.exchangeallowance()
      }
    },
    filters: {
  
      fildecimals(e) {
        const multipliedNumber = e * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        //    console.log(result);
       
        let value = parseFloat(result);
        return value;
      },
    },
    methods: {
        funlastcalltime(){
            const date = this.lastcalltimevalue*1000

console.log(date,'22222222222222222222',this.lastcalltimevalue*1000*24)
// 用法示例

const storedEndTime = date + (1 * 24 * 60 * 60) * 1000;
console.log(storedEndTime, 'aaaaaaaaaaaaa')
// localStorage.getItem('endTime');
if (storedEndTime) {
  this.endTime = parseInt(storedEndTime, 10);
} else {
    console.log('asdasdasdasd')
  this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
}
this.updateCountdown(date);
// setInterval(this.updateCountdown, 1000);
this.timer = setInterval(() => {
  //需要定时执行的代码
  this.updateCountdown(date)
}, 1000)
        },
        startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
        updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }



      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if(this.pad(hours)==null){
        this.countdown=0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign.getauthorization(this.addresscode).then((res) => {
  
          if (res.code == 0) {
            this.users();
  
            this.limitAllInvestAmount();
            this.minInvestAmount();
            this._falconAddress();
            this.isOpen();
            this.balance()
            this.balance2()
            this.onMATIC()
            this.v2proAddress()
  
  
            // this._flashAddress()
          }
          this.isloading = false;
        });
      },
      onnav(e){
        this.daiValue=0
          this.daiValue2=0
          this.daiValue3=0
          this.falvalue1=0
              this.falvalue2=0
              this.falvalue3=0
              this.falvalue4=0
              if(e==1){
                this.navtype=1
              }else if(e==2){
                this.navtype=2
                // if(this.falconuserdata.daiAmount > 0 || this.maticvalue > 0 || this.getMyUserFalconvalue > 0 || this.interestvalue > 0){
                //     this.navtype=2
                // }
              }
          
  
      },
      async withdrawAll() {
        this.isloading = true;
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.Falconv2pro
        try {
          await Falcon2js.withdrawAll(
            this.addresscode,
            ContractAddress,
            _decimals,
          ).then((res) => {
            console.log(res, "withdrawAll");
            if (res.code == 0) {
              this.isloading = false;
              this.balance()
              this.balance2()
              this.onMATIC()
              this.exchangeallowance()
              this.falconuser()
              // this._flashAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
          });
        } catch (error) {
          console.log(error)
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
     
      async pickWithdraw() {
        this.isloading = true;
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address
        try {
          await contract.pickWithdraw(
            this.addresscode,
            ContractAddress,
            _decimals,
            this.falvalue1,
            this.falvalue2,
            this.falvalue3,
          ).then((res) => {
            console.log(res, "pickWithdraw");
            if (res.code == 0) {
              this.isloading = false;
              
              this.falvalue1=0
              this.falvalue3=0
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this._falconAddress();
              this.isOpen();
              this.balance()
              this.balance2()
              this.onMATIC()
              this.v2proAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
          });
        } catch (error) {
          console.log(error)
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
      async v2proAddress() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.falconv3_token.address;
        await Falcon2js.v2proAddress(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "v2proAddress");
          this.Falconv2pro = res.data
          this.falconuser()
          this.exchangeallowance();
        });
      },
      async falconuser() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.Falconv2pro;
        await Falcon2js.usersv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "falconuser");
          if (res.code == 0) {
            this.falconuserdata = res.data
            this.isV2 = res.data.isV2
          } else {
            this.falconuserdata = {
              daiAmount: 0,
              falconAmount: 0
            }
          }
          console.log(this.falconuserdata, 'falconuserfalconuser')
        });
  
        await Falcon2js.getMyUserMaticv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserMaticv2");
          this.maticvalue = res.data
        });
        await Falcon2js.getMyUserDaiInterestv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserDaiInterestv2");
          this.interestvalue = res.data
        });
        await Falcon2js.getMyUserFalconv2(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "getMyUserFalconv2");
          this.getMyUserFalconvalue = res.data
        });
      },
      async onMATIC() {
        var that = this
        this.getMaticBalancevalue = parseFloat(
          await web3Utils.getMaticBalance(this.addresscode)
        );
  
        console.log(this.getMaticBalancevalue, 'getMaticBalancevalue')
      },
      async _falconAddress() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.Falocnn_token.address;
        await Falocnn._falconAddress(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          // console.log(res, "_falconAddress");
          this.falconAddresstype = res.data;
        });
      },
      async isOpen() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.Falocnn_token.address;
        await Falocnn.isOpen(this.addresscode, ContractAddress, _decimals).then(
          (res) => {
            // console.log(res, "isOpen");
            this.isOpentype = res.data;
  
          }
        );
      },
      async _flashAddress() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.Falocnn_token.address;
        await Falocnn._flashAddress(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          // console.log(res, "_flashAddress");
          this.flashAddresstype = res.data;
          if (this.flashAddresstype) {
            this.promptvalue = this.$t("network.falocnerror");
          } else {
            this.promptvalue = this.$t("network.falocninfo");
          }
        });
      },
      async getdata() {
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
        //   this.daiOwnMoney = parseFloat(
        //     await web3Utils.getDaiBalance(this.addresscode)
        //   );
  
        
          this.users();
          this.limitAllInvestAmount();
          this.minInvestAmount();
          //this.exchangeallowance()
        }
      },
      validateAmount() {
  
        if (this.daiValue * 1 < 1000) {
          // this.isButtonDisabled = true;
          // this.depositDisabled = true;
        } else {
          // this.isButtonDisabled = false;
          // this.depositDisabled = false;
          this.daiValue = 1000
          this.exchangeallowance();
        }
      },
      // Falcon余额
      async balance() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.Falcon_token.address;
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
          _decimals
        ).then((result) => {
          console.log(result, "Falcon余额");
          this.Falconbalance = result.data
  
        });
      },
       // usdt余额
       async balance2() {
        var that = this
        let _decimals = 6;
        let spender;
        let Address;
        Address = this.sitedata.USDT_swap.address
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
  
          _decimals
        ).then((result) => {
          console.log(result, "usdt余额");
          this.balanceusdt=result.data
          this.isloading = false;
  
        });
      },
      // Authorization button
      empower() {
        this.isloading = true;  // open loading
        this.Approve();
      },
      // falcon授权
      empowerfalcon() {
        this.isloading = true;  // open loading
        this.Approve2();
      },
      // contract Approve
      async Approve() {
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.USDT_swap.address;
        spender = this.sitedata.aave_token.address;
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens(
            Address, //
            this.daiValue, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbutton = true;
              this.validateAmount();
            } else {
              // this.typeApprove = 1;
              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      async Approve2() {
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.Falcon_token.address;
        spender = this.sitedata.aave_token.address;
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens(
            Address, //
            this.daiValue, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.Falconall = true;
              this.validateAmount();
            } else {
              // this.typeApprove = 1;
              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      // View the number of authorized contracts
      async exchangeallowance() {
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.USDT_swap.address;
        spender = this.sitedata.aave_token.address;
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result.data, 'usdt授权数量')
          if (result.data * 1 > 0) {
            this.empowerbutton = true;
          } else {
            this.empowerbutton = false;
          }
          // console.log(this.daiValue <= result.data * 1, '授权数量授权数量')
          // if (this.daiValue <= result.data * 1) {
          //     this.depositDisabled = false;
          // } else {
          //     this.depositDisabled = true;
          // }
        });
        var Address2 = this.sitedata.Falcon_token.address;
        await Plugens.allowancetoken(
          Address2,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result.data, 'Falcon授权数量')
          if (result.data * 1 > 0) {
            this.Falconall = true;
          } else {
            this.Falconall = false;
          }
  
        });
      },
      // pickDeposit button
      async pickDeposit() {
        this.isloading = true;
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address
        try {
          await contract.pickDeposit(
            this.addresscode,
            ContractAddress,
            _decimals,
            this.daiValue,
            this.daiValue2,
          ).then((res) => {
            console.log(res, "pickDeposit");
            if (res.code == 0) {
              this.isloading = false;
              
              this.daiValue=0
          this.daiValue2=0
              this.users();
              this.limitAllInvestAmount();
              this.minInvestAmount();
              this._falconAddress();
              this.isOpen();
              this.balance()
              this.balance2()
              this.onMATIC()
              this.v2proAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
          });
        } catch (error) {
          console.log(error)
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
      // Need investment
      async limitAllInvestAmount() {
        //  console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("Need investment");
        ContractAddress = this.sitedata.aave_token.address;
        // await contract
        //   .limitAllInvestAmount(
        //     this.addresscode, //Current user address
        //     ContractAddress, //Call contract
        //     _decimals
        //   )
        //   .then((res) => {
        //     console.log(res, "limitAllInvestAmount");
        //     this.needinvest = (res.data * 1) / 1000000;
        //     this.allInvestAmount(res.data);
        //   });
      },
      // invested
      async allInvestAmount(value) {
        // console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("invested");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .allInvestAmount(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            //  console.log(res, "allInvestAmount");
            const total = value * 1;
            //  console.log(total);
            this.currentProgress = ((res.data * 1) / total) * 100;
            this.currentProgress = this.currentProgress.toFixed(2) * 1;
            this.currentProgress = this.currentProgress - 2;
          });
      },
      // Minimum total input
      async minInvestAmount() {
        // console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("Minimum total input");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .minInvestAmount(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            //      console.log(res, "Minimum total input");
            this.Minimal = res.data;
          });
      },
      // User information
      async users() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .users(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            // console.log(res.data,'levellevellevel')
            this.honorLeave = res.data.level
  
            //console.log(res, "User information");
            this.usersdata = res.data;
            this.stakedMoney = res.data.investAmount * 1;
            //console.log(this.stakedMoney);
            //const total = 14000;
            //this.currentProgress = (this.stakedMoney / total) * 100;

            this.pickUsers()
            this.pickGetMyUserFalcon()
            this.pickGetMyUserDaiInterest()
          });
      },
      async pickUsers() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickUsers')
            this.usdtAmount=res.data.usdtAmount
            this.falconAmount=res.data.falconAmount
            this.lastcalltimevalue=res.data.endTime
          
console.log(this.isTimestampLessThanCurrent( this.lastcalltimevalue*1000),'应该输出 true'); // 应该输出 true 
if(this.isTimestampLessThanCurrent( this.lastcalltimevalue*1000)){
    this.lastcalltimevaluetype=true
}else{
    this.lastcalltimevaluetype=false
    this.funlastcalltime()
}
           
          });
      },
      isTimestampLessThanCurrent(timestamp) {
    const currentTimestamp = Date.now();
    return timestamp < currentTimestamp;
},
      async pickGetMyUserFalcon() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickGetMyUserFalcon(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickGetMyUserFalcon')
           this.pickGetMyUserFalconvalue=res.data
          });
      },
      async pickGetMyUserDaiInterest() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pickGetMyUserDaiInterest(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res,'pickGetMyUserDaiInterest')
           this.pickGetMyUserDaiInterestvalue=res.data
          });
      },
      // tb地址接口
      getindex() {
        this.isloading = true;  // open loading
        axios
          .get(this.sitedata.url.address + '/api/index/v2?address=' + this.addresscode + '&amount=' + this.daiValue, {})
          .then(res => {
            console.log(res)
            if (res.data.code == 0) {
              this.returndatadata = res.data.data
              this.stake()
            } else {
              this.$Notice.warning({
                title: res.data.msg,
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            }
  
          })
      },
  
      // Stake button
      async stake() {
  
        var that = this
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        const getGasPrice = await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        console.log(getGasPrice, 'getGasPricegetGasPricegetGasPrice')
        //  预估
  
  
        const estimatedGas = {
          from: that.addresscode, // 替换为发送者地址
          to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
          data: that.returndatadata, // 替换为转账金额
        }
        console.log(estimatedGas)
        // web3.eth.sendTransaction({
        //         from: that.addresscode, // 替换为发送者地址
        //         to: '0x905952f886282D90b308686c048f6F7F44495D86', // 替换为收款地址
        //         data: that.returndatadata, // 替换为转账金额
        //         gas: 41000,
        //         gasPrice: getGasPrice,
        //         // nonce: await web3.eth.getTransactionCount(that.addresscode), //
        //     })
        //     .then((res) => {
        //         console.log('sendTransaction:', res);
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //     });
        web3.eth.estimateGas(estimatedGas)
          .then((res) => {
            console.log('Estimated Gas:', res);
            web3.eth.sendTransaction({
              from: that.addresscode, // replace sender address
              to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
              data: that.returndatadata, // 替换为转账金额
              gas: res,
              gasPrice: Math.round(getGasPriceto * 1.5),
            })
              .then((res) => {
                console.log('sendTransaction:', res);
                this.isloading = false;
                this.users();
                this.exchangeallowance();
                this.limitAllInvestAmount();
                this.minInvestAmount();
                this._falconAddress();
                this.isOpen();
                this.balance()
                this.balance2()
                this.onMATIC()
                this.$Notice.success({
                  title: this.$t("network.flashloansOK"),
                  //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                });
              })
              .catch((error) => {
                console.error('Error:', error);
                this.isloading2 = false;
                this.$Notice.warning({
                  title: this.$t("network.flashloansNo"),
                  //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                });
              });
          })
          .catch((error) => {
            this.isloading2 = false;
            this.$Notice.warning({
              title: this.$t("network.flashloansNo"),
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            console.error('Error:', error);
          });
  
  
      },
      // tb地址接口
      // getindex() {
      //     this.isloading = true; // open loading
      //     var datas = {
      //         address: this.addresscode,
      //         amount: this.daiValue,
      //         lever: this.honorLeave.toString()
      //     }
  
      //     let jsonData = JSON.stringify(datas);
  
  
      //     var base64 = window.btoa(jsonData)
  
  
      //     axios
      //         .get(
      //             this.sitedata.url.address+"/api/index/falcon?data=" +
      //             base64,
      //             {}
      //         )
      //         .then((res) => {
  
      //             this.returndatadata = res.data.data;
      //             this.stake();
      //         });
      // },
  
      // // Stake button
      // async stake() {
      //     let _decimals = 18;
      //     let ContractAddress;
      //     ContractAddress = this.sitedata.Falocnn_token.address;
      //     try {
      //         await Falocnn.falcon(
      //             this.addresscode,
      //             ContractAddress,
      //             this.returndatadata,
      //             _decimals
      //         ).then((res) => {
      //             // console.log(res, "falcon");
      //             if (res.code == 0) {
      //                 this.isloading = false;
      //                 this._falconAddress();
      //                 this.isOpen();
      //                 // this._flashAddress()
      //                 this.$Notice.success({
      //                     title: this.$t("network.flashloansOK"),
      //                     //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
      //                 });
      //             } else {
      //                 this.isloading = false;
      //                 this.$Notice.warning({
      //                     title: this.$t("network.flashloansNo"),
      //                     //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
      //                 });
      //             }
      //         });
      //     } catch (error) {
      //         // this.typeApprove = 1;
      //         this.isloading = false;
      //         this.$Notice.warning({
      //             title: this.$t("network.flashloansNo"),
      //             //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
      //         });
      //         //console.log(error);
      //     }
      // },
  
  
      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.balanceusdt) return "0.0000";
  
        const multipliedNumber = this.balanceusdt * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
      
        let value = parseFloat(result);
  
  
        return value;
      },
      formattedFalconbalance() {
        if (!this.Falconbalance) return "0.0000";
        console.log(this.Falconbalance)
        const multipliedNumber = this.Falconbalance * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        let value = parseFloat(result);
        console.log(value)
  
        return value
      },
      formattedDaiOwnMoney2() {
        if (!this.stakedMoney) return "0.0000";
        const multipliedNumber = this.stakedMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value;
      },
    },
  };
  </script>
  