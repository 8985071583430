import { render, staticRenderFns } from "./EvFalocnV4.vue?vue&type=template&id=2829aeb2&scoped=true&"
import script from "./EvFalocnV4.vue?vue&type=script&lang=js&"
export * from "./EvFalocnV4.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/EvFalocnV4.css?vue&type=style&index=0&id=2829aeb2&prod&scoped=true&lang=css&"
import style1 from "./EvFalocnV4.vue?vue&type=style&index=1&id=2829aeb2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_2c66e885b17cbecb4aa951ec806096ab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2829aeb2",
  null
  
)

export default component.exports