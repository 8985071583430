<template>
    <div class="stakewait">
        <!-- notification start -->
        <div class="marquee">
            <!-- <Marquee :duration="15">{{ $t("network.falconv4") }}</Marquee> -->
            <!-- <Marquee :duration="15">{{ $t("menu.LogiV3tips") }}</Marquee> -->
        </div>
        <!-- notification end -->
        <div class="coinInfomation">
            <div class="coinImg"><img :src="falocn" class="dailogo" /></div>
            <div class="coinInfo">{{ $t("menu.Ido") }}</div>
        </div>
        <Divider style="background: rgba(142, 200, 243, 0.1)" />
        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("ido.mynumber") }}:</div>
        </div>
        <div class="inputInfo">
            <input class="inputStyle" v-model="decimalsvalue1" />
        </div>


        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("ido.myrelease") }}:</div>
        </div>
        <div class="inputInfo">
            <input class="inputStyle" v-model="decimalsvalue2" />
        </div>


        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("ido.waitrelease") }}: ({{ steps }}/12)</div>
        </div>
        <div class="inputInfo">
            <input class="inputStyle" v-model="decimalsvalue3" />
        </div>


        <button @click="release()" v-if="getpledgepervalue > 0" class="tbnStyle">{{ $t("ido.get") }}</button>
        <button v-else class="tbnStyle tbnStyle_type">{{ $t("ido.get") }}</button>






        <div><img class="bStyle" :src="stylea" alt="" /></div>
        <!--loading start-->
        <div v-if="isloading" class="loading">
            <img :src="loadingimg" class="loadimg" />
        </div>
        <!--loading end-->
    </div>
</template>
<style scoped src="@/assets/css/FalconIdo2.css"></style>
<style lang="less" scoped>

</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FG1 from "@/assets/img/FG1.svg";
import falocn from "@/assets/img/FalconLogoNew.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import Falocnn from "@/plugins/Falocnn.js";
import idojs from "@/plugins/idojs.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
    data() {
        return {
            isButtonDisabled: true,
            depositDisabled: true,
            typeApprove: 0,
            typedeposit: 0,
            dai: dai,
            FG1: FG1,
            falocn: falocn,
            daimni: daimni,
            loadingimg: loadingimg,
            daiValue: "100",
            daiOwnMoney: 0,
            stylea: stylea,
            stakedMoney: 0,
            currentProgress: 0,
            usersdata: {},
            sitedata: {},
            addresscode: "",
            empowerbutton: true,
            needinvest: "",
            Minimal: 0,
            isloading: false,
            falconAddresstype: true,
            isOpentype: false,
            flashAddresstype: true,
            returndatadata: "",
            promptvalue: "",
            honorLeave: "",
            selected: 0,
            options: [
                { text: this.$t("ido.select"), value: "none" },
                { text: this.$t("ido.flashmonkey"), value: "0x18FBD380aD51A4bcb3A1A8a89107483d10B716c0" },
                { text: this.$t("ido.flashdragon"), value: "0xd16BCe7f276947Cf61453E74a67b267F0e807DD6" },
                { text: this.$t("ido.powermonger"), value: "0x357AA453A873eF716eA69088C85Be4aDBDA6282e" },
                { text: this.$t("ido.fashfalcon2"), value: "0x14c26324f39b35F908B79c2c240c187520f6D772" },
                { text: this.$t("ido.fashFalcon"), value: "0x7cDFf0d91181993eBD88e058095060e71eAB3Eba" },
            ],
            Tobereleased: 0,
            totalAmount: 0,
            releasedAmount: 0,
            releaseCount: 0,
            getpledgepervalue: 0,
            haveAmount: 0,
            steps: 0


        };
    },
    components: {

        //ProgressBar,
    },

    async mounted() {

        // Determine whether the wallet is connected
        if (typeof window.ethereum !== "undefined") {
            //console.log("Connected wallet");

            // Async obtaining network information
            window.ethereum
                .request({ method: "eth_chainId" })
                .then((chainId) => {
                    // is Polygon?
                    if (chainId === "0x89") {
                        // console.log("The current network is a Polygon chain");
                        // this.$router.push({path:'/EvStake'});
                    } else {
                        //  console.log("The current network is not Polygon chain");
                        this.$router.push({ path: "/" });
                        return;
                    }
                })
                .catch((error) => {
                    console.error(
                        "Failed to obtain network information. Procedure:",
                        error
                    );
                });
        } else {
            //console.log("Unconnected wallet");
        }
        this.sitedata = siteapi;
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
            this.daiOwnMoney = parseFloat(
                await web3Utils.getDaiBalance(this.addresscode)
            );

            //  console.log(this.daiOwnMoney.toFixed(6));

            this.signtype();
            // this.exchangeallowance()
        }
    },
    methods: {
        // 签名验证
        async signtype() {
            this.isloading = true;
            await sign.getauthorization(this.addresscode).then((res) => {

                if (res.code == 0) {
                    // this.users();
                    this.vestingSchedules()

                    // this._flashAddress()
                }
                this.isloading = false;
            });
        },


        async vestingSchedules() {

            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.ido_token.address;
            await idojs.vestingSchedules(
                this.addresscode,
                ContractAddress,
                _decimals,
            ).then((res) => {
                console.log(res, "vestingSchedules");
                this.totalAmount = res.data.totalAmount
                this.releasedAmount = res.data.releasedAmount * 1
                this.releaseCount = res.data.releaseCount
                this.Tobereleased = res.data.totalAmount - res.data.releasedAmount

                this.haveAmount = res.data.haveAmount

                this.getpledgeper()

            });
        },
        async getpledgeper() {

            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.ido_token.address;
            await idojs.getpledgeper(
                this.addresscode,
                ContractAddress,
                _decimals,
            ).then((res) => {
                console.log(res, "getpledgeper");
                this.getpledgepervalue = res.data * 1;//+this.releasedAmount;
                let remainingAmount = this.totalAmount - this.releasedAmount;

                let stepAmount = this.totalAmount / 12;
                if (stepAmount == 0) {
                    this.steps = 0
                } else {
                    this.steps = Math.ceil(remainingAmount / stepAmount);
                }

                console.log(this.steps)

            });
        },
        // User information
        async users() {
            //console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            //console.log("User information");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .users(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    // console.log(res.data,'levellevellevel')
                    this.honorLeave = res.data.level

                    //console.log(res, "User information");
                    this.usersdata = res.data;
                    this.stakedMoney = res.data.investAmount * 1;
                    //console.log(this.stakedMoney);
                    //const total = 14000;
                    //this.currentProgress = (this.stakedMoney / total) * 100;
                });
        },
        // 提取
        async release() {
            this.isloading = true
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.ido_token.address;
            try {
                await idojs.release(
                    this.addresscode,
                    ContractAddress,
                    _decimals

                ).then((res) => {
                    console.log(res, "release");
                    if (res.code == 0) {
                        this.isloading = false;
                        this.vestingSchedules()

                        this.$Notice.success({
                            title: this.$t("network.flashloansOK"),
                            //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                        });
                    } else {
                        this.isloading = false;
                        if (res.code < 1) {
                            this.$Notice.warning({
                                title: res.error.message
                            });
                        } else {
                            this.$Notice.warning({
                                title: this.$t("network.flashloansNo")
                                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                            });
                        }

                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("network.flashloansNo"),
                    //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                });
                //console.log(error);
            }
        },



        warning(nodesc) {
            this.$Notice.warning({
                title: nodesc ? "" : this.$t("network.stake"),
                //desc: nodesc ? "" : this.$t("network.stake"),
            });
        },
    },
    computed: {
        decimalsvalue1() {
            if (!this.totalAmount) return "0.00";

            const multipliedNumber = this.totalAmount * 1000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 1000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);


            return value;
        },
        decimalsvalue2() {
            if (!this.getpledgepervalue) return "0.00";
            const multipliedNumber = this.getpledgepervalue * 1000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 1000;
            let value = parseFloat(result);
            //console.log(value)
            return value;
        },
        decimalsvalue3() {
            if (!this.haveAmount) return "0.00";
            const multipliedNumber = this.haveAmount * 1000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 1000;
            let value = parseFloat(result);
            //console.log(value)
            return value;
        },
    },
};
</script>
