<template>
  <div>
    <div class="stakewait">
      <div class="coinInfomation">
        <div class="coinInfo">{{ $t("Share.Invitelink") }}</div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <div class="inputInfoOk">
        <input v-model="shortenedAddress" type="text" class="inputStyle" />
      </div>

      <div class="Qrcode">
        <QrCodeGenerator :text="`${appurl}?ref=${this.invAddress}`"></QrCodeGenerator>
      </div>
      <div class="StakeAuthor">
        <button class="StakeBtn2 ripple" v-clipboard:copy="linkAddress2" v-clipboard:success="onCopy"
          v-clipboard:error="onError">
          {{ $t("Share.copyCommnad") }}
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/EvShare.css"></style>
<style lang="less" scoped>

</style>
<script>
import QrCodeGenerator from "@/components/QrCodeGenerator.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import link from "@/assets/img/link.svg";
import honor from "@/assets/img/honor.svg";
import VueClipboard from "vue-clipboard2";
//import PullRefresh from "vue-pull-refresh";
import Vue from "vue";
Vue.use(VueClipboard);
export default {
  data() {
    return {
      isBind: false,
      link: link,
      honor: honor,
      dai: dai,
      daimni: daimni,
      invAddress: "",
      daiOwnMoney: 1200.03,
      stylea: stylea,
      quer: this.$route.query.rel,
      inputContent: "Text to copy",
      copySuccess: this.$t("Share.copyTitle"),
      copySuccessText: this.$t("Share.copyText"),
      appurl:process.env.VUE_APP_URL
    };
  },
  components: {
    // PullRefresh,
    QrCodeGenerator,
  },
  computed: {
    shortenedAddress: {
      get() {
        if (this.invAddress === "") {
          return "0x";
        } else {
          return this.invAddress.slice(0, 8) + " ••• " + this.invAddress.slice(-9);
        }
      },
      set(newVal) {
        this.invAddress = newVal;
      },
    },
    linkAddress2: {
      get() {
        return `${process.env.VUE_APP_URL}?ref=${this.invAddress}`;
      },
    },
  },
  methods: {
    refresh(done) {
      setTimeout(() => {
        done(); // call done() to end refreshing
      }, 1500);
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("Team.Teamstaked"),
        //desc: nodesc ? "" : this.$t("Team.Teamstaked"),
      });
    },
    success(nodesc) {
      this.$Notice.success({
        title: nodesc ? "" : this.copySuccessText,
        //desc: nodesc ? "" : this.copySuccessText,
      });
    },

    // copyText() {
    //     this.$clipboard(this.inputContent).then(
    //         function (e) {
    //             console.log(e);
    //         },
    //         function (err) {
    //             console.error('Failed to copy text: ', err);
    //         }
    //     );
    // },
    onCopy() {
      this.success(false);
      // console.log('Text successfully copied');
    },
    onError() {
      console.error("Failed to copy text");
    },
  },
  async mounted() {
    this.invAddress = await web3Utils.connectWallet();
  },
};
</script>
