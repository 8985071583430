<template>
    <div class="ContractPage">
        <div class="tempContent">
            <div class="stakewait">
                <div class="coinInfomation">
                    <div class="coinInfo"> {{ $t("swap.Swap") }}</div>
                </div>

                <div class="payment">
                    <div class="pname">{{ $t("swap.paynameA") }}</div>
                    <div class="pmoney">
                        <div class="pmoneynumer">
                            <input type="number" placeholder="0.0" v-model="swap_Avalue" @input="oninput">
                        </div>
                        <div class="pmoneyCass" @click="swap_type = 1, isShown2 = !isShown2">
                            <img :src="swap_aimg" class="swapmatic" />
                            <div class="coinname">{{ swap_Aname }}</div>
                            <img :src="swap_arrow" class="swaparrow" />
                        </div>
                    </div>

                    <div class="ptips">
                        <div class="left"> US ${{ formdestUSD }}</div>
                        <div class="right" @click="swap_Avalue = forswap_Abalance2">{{ forswap_Abalance }} {{ swap_Aname
                            }}</div>
                    </div>
                </div>

                <div class="transimg"><img @click="reversal()" :src="swap_yellow" class="swapmatic" /></div>

                <div class="payment">
                    <div class="pname">{{ $t("swap.paynameB") }}</div>
                    <div class="pmoney">
                        <div class="pmoneynumer"> <input disabled type="number" placeholder="0.0"
                                v-model="formattedDaiOwnMoney"></div>
                        <div class="pmoneyCass" @click="swap_type = 2, isShown2 = !isShown2">
                            <img :src="swap_bimg" class="swapmatic" />
                            <div class="coinname">{{ swap_Bname }}</div>
                            <img :src="swap_arrow" class="swaparrow" />
                        </div>
                    </div>

                    <div class="ptips">
                        <div class="left"> US ${{ forsrcUSD }}</div>
                        <div class="right">{{ forswap_Bbalance }} {{ swap_Bname }}</div>
                    </div>
                </div>

                <div class="price">
                    <div class="left">1{{ swap_Aname }}={{ formattedDaiOwnMoney2 }}{{ swap_Bname }}(${{
                        formattedDaiOwnMoney2 }})<img @click="onrefresh()" :src="swap_trans"></div>
                    <div class="right">
                        <img :src="swap_gas" class="gasimg" />
                        <div>${{ forgasCostUSD }}</div>
                        <img :src="swap_arrow2" class="arrimg" @click="isShown = !isShown">
                    </div>
                </div>

                <div class="otherInfoswith" v-show="isShown">
                    <div class="otherInfo">
                        <div class="left">Order Routing</div>
                        <div class="right">{{ exchange }}</div>
                    </div>

                    <!-- <div class="otherInfo">
                        <div class="left">价格影响</div>
                        <div class="right">0.10%</div>
                    </div>
                    <div class="otherInfo">
                        <div class="left">最大支付限额</div>
                        <div class="right">28.9740MATIC</div>
                    </div> -->
                    <div class="otherInfo">
                        <div class="left">Network Fees</div>
                        <div class="right">{{ forgasCostUSD }}</div>
                    </div>
                </div>
                <div class="StakeAuthor" v-if="swap_Aname == 'FALCON' && swap_Bname == 'DAI'">

                    <button class="StakeBtn2 ripple" v-if="empowerbutton2" @click="Approve2()">
                        {{ $t("swap.Approve") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else-if="buttonshow == false">
                        {{ $t("swap.launchSwap") }}
                    </button>
                    <button class="StakeBtn2 ripple" v-else @click="swapExactTokens()">
                        {{ $t("swap.launchSwap") }}
                    </button>
                </div>
                <div class="StakeAuthor" v-else-if="swap_Aname == 'FALCON' && swap_Bname == 'POL'">

                    <button class="StakeBtn2 ripple" v-if="empowerbutton2" @click="Approve2()">
                        {{ $t("swap.Approve") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else-if="buttonshow == false">
                        {{ $t("swap.launchSwap") }}
                    </button>
                    <button class="StakeBtn2 ripple" v-else @click="FeeOnTransferTokens()">
                        {{ $t("swap.launchSwap") }}
                    </button>
                </div>
                <div class="StakeAuthor" v-else-if="swap_Aname == 'FALCON' && swap_Bname == 'USDT'">

                    <button class="StakeBtn2 ripple" v-if="empowerbutton2" @click="Approve2()">
                        {{ $t("swap.Approve") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else-if="buttonshow == false">
                        {{ $t("swap.launchSwap") }}
                    </button>
                    <button class="StakeBtn2 ripple" v-else @click="swapExactTokens2()">
                        {{ $t("swap.launchSwap") }}
                    </button>
                </div>
                <div class="StakeAuthor" v-else-if="swap_Aname == 'FALCON' && swap_Bname == 'USDC'">

                    <button class="StakeBtn2 ripple" v-if="empowerbutton2" @click="Approve2()">
                        {{ $t("swap.Approve") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else-if="buttonshow == false">
                        {{ $t("swap.launchSwap") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else>
                        {{ $t("swap.launchSwap") }}
                    </button>
                </div>

                <div class="StakeAuthor" v-else>

                    <button class="StakeBtn2 ripple" v-if="empowerbutton" @click="Approve()">
                        {{ $t("swap.Approve") }}
                    </button>
                    <button class="StakeBtn3 ripple" v-else-if="buttonshow == false">
                        {{ $t("swap.launchSwap") }}
                    </button>
                    <button class="StakeBtn2 ripple" v-else @click="getexchange()">
                        {{ $t("swap.launchSwap") }}
                    </button>
                </div>
                <div><img class="bStyle" :src="stylea" alt="" /></div>

                <div class="selectCoin" v-show="isShown2">
                    <div class="tipsandclose">
                        <div class="t1">{{ $t("swap.recommend") }}（Polygon）</div>
                        <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
                    </div>
                    <div class="allconin">
                        <div class="coinBTN" @click="onselect(swap_dai, 'DAI', 'DAI_swap', '18')">
                            <img :src="swap_dai" alt="">
                            <div class="coiname">DAI</div>
                        </div>
                        <div class="coinBTN" @click="onselect(swap_matic, 'POL', 'POL', '18')">
                            <img :src="swap_matic" alt="">
                            <div class="coiname">POL</div>
                        </div>
                        <!-- @click="onselect(swap_falcon,'FALCON','falcon_swap','18')" -->
                        <div class="coinBTN" @click="onselect(swap_falcon, 'FALCON', 'falcon_swap', '18')">
                            <img :src="swap_falcon" alt="">
                            <div class="coiname">FALCON</div>
                        </div>
                        <div class="coinBTN" @click="onselect(swap_usdt, 'USDT', 'USDT_swap', '6')">
                            <img :src="swap_usdt" alt="">
                            <div class="coiname">USDT</div>
                        </div>
                        <div class="coinBTN" @click="onselect(swap_usdc, 'USDC', 'USDC_swap', '6')">
                            <img :src="swap_usdc" alt="">
                            <div class="coiname">USDC</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--loading start-->
        <div v-if="isloading" class="loading">
            <img :src="loadingimg" class="loadimg" />
        </div>
        <!--loading end-->
    </div>
</template>

<script>
import loadingimg from "@/assets/img/loading.svg";
import EvWatch from "@/assets/img/EvWatch.svg";
import matic from "@/assets/img/matic.svg";
import EvCon from "@/assets/img/EvCon.svg";
import VueClipboard from "vue-clipboard2";
import stylea from "@/assets/img/stylea.png";
import siteapi from "@/json/siteapi.json";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import swap_arrow2 from "@/assets/img/swap_arrow2.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_gas from "@/assets/img/swap_gas.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_trans from "@/assets/img/swap_trans.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import swap_yellow from "@/assets/img/swap_yellow.svg";
import web3Utils from "@/utils/web3Utils.js";
import Web3 from "web3";
import sign from "@/plugins/sign.js";
import Plugens from "@/plugins/index";
import swapFalconjs from "@/plugins/swapFalconjs.js";
import axios from "axios";
import big from "big.js";
import Vue from "vue";
Vue.use(VueClipboard);
export default {
    name: "EvContract",
    data() {
        return {
            loadingimg,
            matic,
            EvWatch,
            EvCon,
            stylea,
            swap_arrow,
            swap_arrow2,
            swap_close,
            swap_dai,
            swap_falcon,
            swap_gas,
            swap_matic,
            swap_trans,
            swap_usdt,
            swap_usdc,
            swap_yellow,
            swap_aimg: swap_matic,
            swap_Aname: "POL",
            swap_Abalance: "0",
            swap_Avalue: 1,
            swap_decimalsA: 18,
            swap_Atoken: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            swap_type: "1",
            swap_bimg: swap_dai,
            swap_Bname: "DAI",
            swap_Bbalance: "0",
            swap_Bvalue: '',
            swap_decimalsB: 18,
            swap_Btoken: siteapi.DAI_swap.address,
            invAddress: siteapi.aave_token.address,
            copySuccess: this.$t("Contract.Copylink"),
            copySuccessText: this.$t("Contract.CopylinkText"),
            isShown: true,
            isShown2: false,
            destUSD: "",
            empowerbutton: false,
            empowerbutton2: false,
            priceRoute: {},
            returndatadata: "",
            timerId: null,
            gasCostUSD: 0,
            exchange: "",
            srcUSD: 0,
            second: 3000,
            buttonshow: false,
            isloading: false,
            refreshshow: 1

        };
    },
    async mounted() {
        // Determine whether the wallet is connected
        if (typeof window.ethereum !== "undefined") {
            //console.log("Connected wallet");

            // Async obtaining network information
            window.ethereum
                .request({ method: "eth_chainId" })
                .then((chainId) => {
                    // is Polygon?
                    if (chainId === "0x89") {
                        // console.log("The current network is a Polygon chain");
                        // this.$router.push({path:'/EvStake'});
                    } else {
                        //  console.log("The current network is not Polygon chain");
                        this.$router.push({ path: "/" });
                        return;
                    }
                })
                .catch((error) => {
                    console.error("Failed to obtain network information. Procedure:", error);
                });
        } else {
            //console.log("Unconnected wallet");
        }
        this.sitedata = siteapi;
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
            this.daiOwnMoney = parseFloat(
                await web3Utils.getDaiBalance(this.addresscode)
            );
            this.signtype()
            console.log(this.daiOwnMoney, 'aa11111111111111111111', this.addresscode);
            await this.onselect(this.swap_matic, 'POL', 'POL', '18', 1)
            await this.onselect(this.swap_dai, 'DAI', 'DAI_swap', '18', 2)


            // this.onMATIC()
            // this.swap_type=2
            // this.balance(this.swap_Btoken)



            // this.exchangeallowance()
        }
    },
    beforeDestroy() {
        // 在组件销毁之前清除定时器
        clearInterval(this.timerId)
    },
    beforeUnmount() {
        // 在组件销毁之前清除定时器 (Vue 3)
        clearInterval(this.timerId)
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开当前组件时清除定时器
        console.log('清除定时器')
        clearInterval(this.timerId)
        next();
    },

    methods: {
        onCopy() {
            window.open(
                `https://polygonscan.com/address/${this.invAddress}`,
                "_blank"
            );

            // this.success(false);
            // console.log('Text successfully copied');
        },
        onError() {
            console.error("Failed to copy text");
        },
        success(nodesc) {
            this.$Notice.success({
                title: this.copySuccess,
                desc: nodesc ? "" : this.copySuccessText,
            });
        },
        // 签名验证
        async signtype() {
            this.isloading = true;
            await sign
                .getauthorization(
                    this.addresscode
                )
                .then((res) => {

                    if (res.code == 0) {
                        // this.users();
                        // this.exchangeallowance();
                        // this.limitAllInvestAmount();
                        // this.minInvestAmount();
                    }
                    this.isloading = false;
                });
        },
        // 反转
        reversal() {
            this.buttonshow = false

            var img = this.swap_aimg
            var name = this.swap_Aname
            var token = this.swap_Atoken
            var decimals = this.swap_decimalsA
            var balance = this.swap_Abalance

            this.swap_aimg = this.swap_bimg
            this.swap_Aname = this.swap_Bname
            this.swap_Atoken = this.swap_Btoken
            this.swap_decimalsA = this.swap_decimalsB
            this.swap_Abalance = 0

            this.swap_bimg = img
            this.swap_Bname = name
            this.swap_Btoken = token
            this.swap_decimalsB = decimals
            this.swap_Bbalance = 0

            if (this.swap_Aname == 'POL') {
                this.empowerbutton = false
                this.empowerbutton2 = false
                // a
                this.onMATIC()
            } else {
                // a
                this.balance(this.swap_Atoken)

                this.exchangeallowance()
                this.exchangeallowance2()
            }

            if (this.swap_Bname == 'POL') {
                //   b
                this.onMATIC2()
            } else {
                //   b
                this.balance2(this.swap_Btoken)
            }


            this.oninput()
        },
        // 选择币种
        async onselect(img, name, site, _decimals, swaptype) {
            if (swaptype) {
                this.swap_type = swaptype
            }
            this.swap_Bvalue = 0
            this.buttonshow = false
            console.log(this.swap_type, 'AAAAAAAAAAAAAA')
            if (this.swap_type == 1) {
                if (name == this.swap_Bname) {
                    console.log('1一致')
                    this.swap_bimg = this.swap_aimg
                    this.swap_Bname = this.swap_Aname
                    this.swap_Btoken = this.swap_Atoken
                    this.swap_decimalsB = this.swap_decimalsA
                    this.swap_Bbalance = this.swap_Abalance
                }
                this.swap_aimg = img
                this.swap_Aname = name
                this.swap_decimalsA = _decimals
                if (site == 'POL') {
                    this.swap_Atoken = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                    await this.onMATIC()
                } else {
                    this.swap_Atoken = this.sitedata[site].address
                    this.balance(this.sitedata[site].address)
                    await this.exchangeallowance()
                    await this.exchangeallowance2()
                }

            } else if (this.swap_type == 2) {
                if (name == this.swap_Aname) {
                    console.log('2一致')
                    this.swap_aimg = this.swap_bimg
                    this.swap_Aname = this.swap_Bname
                    this.swap_Atoken = this.swap_Btoken
                    this.swap_decimalsA = this.swap_decimalsB
                    this.swap_Abalance = this.swap_Bbalance
                }
                this.swap_bimg = img
                this.swap_Bname = name
                this.swap_decimalsB = _decimals


                if (site == 'POL') {
                    this.swap_Btoken = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                    await this.onMATIC2()
                } else {
                    this.swap_Btoken = this.sitedata[site].address
                    await this.balance2(this.sitedata[site].address)
                }
            }

            this.isShown2 = false

        },
        // 刷新
        onrefresh() {
            this.isloading = true;
            this.refreshshow = 2
            this.oninput()
        },
        // input改动
        oninput(e) {
            var that = this
            this.swap_Bvalue = 0
            this.buttonshow = false
            if (this.swap_Avalue > 0) {
                clearInterval(this.timerId)
                this.getindex()
                this.timerId = setInterval(function () {
                    // 要执行的代码或函数
                    that.getindex()
                }, that.second);
            } else {
                clearInterval(this.timerId)
            }
        },
        async onMATIC() {
            var that = this
            this.daiOwnMoney = parseFloat(
                await web3Utils.getMaticBalance(this.addresscode)
            );

            this.swap_Abalance = this.daiOwnMoney
            console.log(this.swap_Abalance)
            console.log(this.daiOwnMoney, 'onMATICaaaaaaaaaaaaaaaaaaaaaaaaa')
            clearInterval(this.timerId)
            this.getindex()
            this.timerId = setInterval(function () {
                // 要执行的代码或函数
                that.getindex()
            }, that.second);
        },
        async onMATIC2() {
            var that = this
            this.daiOwnMoney = parseFloat(
                await web3Utils.getMaticBalance(this.addresscode)
            );

            this.swap_Bbalance = this.daiOwnMoney

            clearInterval(this.timerId)
            this.getindex()
            this.timerId = setInterval(function () {
                // 要执行的代码或函数
                that.getindex()
            }, that.second);
        },
        // 余额a
        async balance(site) {
            var that = this
            let _decimals = this.swap_decimalsA;
            let spender;
            let Address;
            Address = site
            await Plugens.balanceOf(
                Address,
                0,
                this.addresscode,

                _decimals
            ).then((result) => {
                console.log(result, "余额A");

                this.swap_Abalance = result.data



                clearInterval(this.timerId)
                this.getindex()
                this.timerId = setInterval(function () {
                    // 要执行的代码或函数
                    that.getindex()
                }, that.second);
            });
        },
        // 余额b
        async balance2(site) {
            var that = this
            let _decimals = this.swap_decimalsB;
            let spender;
            let Address;
            Address = site
            await Plugens.balanceOf(
                Address,
                0,
                this.addresscode,

                _decimals
            ).then((result) => {
                console.log(result, "余额B");

                this.swap_Bbalance = result.data



                clearInterval(this.timerId)
                this.getindex()
                this.timerId = setInterval(function () {
                    // 要执行的代码或函数
                    that.getindex()
                }, that.second);
            });
        },
        // tb地址接口swap_Avalue
        getindex() {
            var that = this
            try {
                let amountAout = big(this.swap_Avalue)
                    .times(10 ** this.swap_decimalsA)
                    .toFixed();
                axios
                    .get('https://api.paraswap.io/prices/?version=6.2&srcToken=' + this.swap_Atoken + '&destToken=' + this.swap_Btoken + '&amount=' + amountAout + '&srcDecimals=' + this.swap_decimalsA + '&destDecimals=' + this.swap_decimalsB + '&side=SELL&excludeDirectContractMethods=false&network=137&otherExchangePrices=true&partner=paraswap.io&userAddress=' + this.addresscode + '', {

                    })
                    .then(res => {
                        // this.isloading = false;
                        console.log(res)
                        if (this.refreshshow == 2) {
                            this.refreshshow = 1
                            this.isloading = false;
                        }
                        if (res.status == 200) {
                            this.buttonshow = true
                        } else {
                            this.buttonshow = false
                            //  this.isloading = false;
                            this.$Notice.warning({
                                title: this.$t("network.flashloansNo"),
                                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                            });
                            clearInterval(this.timerId)
                            return
                        }
                        console.log(res)
                        this.priceRoute = res.data.priceRoute
                        this.swap_Bvalue = big(res.data.priceRoute.destAmount)
                            .div(10 ** this.swap_decimalsB)
                            .toFixed()
                        this.destUSD = res.data.priceRoute.destUSD
                        this.srcUSD = res.data.priceRoute.srcUSD
                        // this.gasCostUSD=res.data.priceRoute.gasCostUSD
                        console.log(this.destUSD)
                        console.log(res.data.priceRoute.destUSD)
                        this.exchange = res.data.priceRoute.bestRoute[0].swaps[0].swapExchanges[0].exchange
                        console.log(this.exchange)
                    }).catch(error => {
                        // 处理请求错误
                        if (this.refreshshow == 2) {
                            this.refreshshow = 1
                            this.isloading = false;
                        }
                        this.buttonshow = false
                        // this.isloading = false;

                        clearInterval(this.timerId)
                        if (error.response.status == 404) {
                            this.$Notice.warning({
                                title: this.$t("swap.swapError"),
                            });
                        } else {
                            this.$Notice.warning({
                                title: this.$t("swap.swapNetwork"),
                            });
                        }
                        console.error('请求出现错误:', error);
                    });
            } catch (error) {
                console.log(error)
                // this.typeApprove = 1;
                // this.isloading = false;
                this.buttonshow = false
                clearInterval(this.timerId)
                this.$Notice.warning({
                    title: this.$t("stake.AuthorFailed"),
                    // desc: this.$t("stake.AuthorFailed"),
                });
                // console.log(error);
            }
        },
        subtractPercentage(number) {
            number = Number(number);
            var percentage = number * 0.04;
            var newNumber = number - percentage;
            return newNumber.toFixed(0);
        },
        // 交换接口
        async getexchange() {
            const ethereum = window.ethereum;
            const web3 = new Web3(Web3.givenProvider || ethereum);
            const getGasPrice = await web3.eth.getGasPrice()
            const getGasPriceto = getGasPrice.toString();
            // console.log(getGasPrice,Math.round(getGasPriceto*1.5),'getGasPricegetGasPricegetGasPrice')
            let amountAout = big(this.swap_Avalue)
                .times(10 ** this.swap_decimalsA)
                .toFixed();
            let amountBout = big(this.swap_Bvalue)
                .times(10 ** this.swap_decimalsB)
                .toFixed();
            var destAmount = this.subtractPercentage(amountBout)

            //console.log(destAmount,'计算结果计算结果',amountBout,'原数量'); // 输出结果应该是98
            axios
                .post('https://api.paraswap.io/transactions/137/?ignoreGasEstimate=false&ignoreAllowance=false&gasPrice=' + Math.round(getGasPriceto * 1.5), {
                    destAmount: destAmount,
                    destDecimals: this.swap_decimalsB,
                    destToken: this.swap_Btoken,
                    partner: "paraswap.io",
                    priceRoute: this.priceRoute,
                    srcAmount: amountAout,
                    srcDecimals: this.swap_decimalsA,
                    srcToken: this.swap_Atoken,
                    userAddress: this.addresscode
                })
                .then(res => {

                    console.log(res)
                    this.returndatadata = res.data
                    this.stake()
                })
        },
        // 交换
        async stake() {
            this.isloading = true;
            var that = this
            const ethereum = window.ethereum;
            const web3 = new Web3(Web3.givenProvider || ethereum);
            const getGasPrice = await web3.eth.getGasPrice()

            //  预估
            const estimatedGas = {
                from: that.addresscode, // 替换为发送者地址
                to: that.returndatadata.to, // 替换为收款地址
                data: that.returndatadata.data, // 替换为转账金额
            }
            console.log(estimatedGas)

            web3.eth.sendTransaction({
                from: that.addresscode, // replace sender address
                to: that.returndatadata.to, // 替换为收款地址
                data: that.returndatadata.data, // 替换为转账金额
                gas: that.returndatadata.gas,
                gasPrice: that.returndatadata.gasPrice,
                value: that.returndatadata.value
            })
                .then((res) => {
                    console.log('sendTransaction:', res);
                    this.isloading = false;

                    this.$Notice.success({
                        title: this.$t("network.flashloansOK"),
                        //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                    });
                    // 交换后重新获取余额
                    this.swap_Abalance = 0
                    this.swap_Bbalance = 0
                    if (this.swap_Aname == 'POL') {
                        this.empowerbutton = false
                        this.empowerbutton2 = false
                        // a
                        this.onMATIC()
                    } else {
                        // a
                        this.balance(this.swap_Atoken)

                        this.exchangeallowance()
                        this.exchangeallowance2()
                    }

                    if (this.swap_Bname == 'POL') {
                        //   b
                        this.onMATIC2()
                    } else {
                        //   b
                        this.balance2(this.swap_Btoken)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.isloading = false;
                    this.$Notice.warning({
                        title: this.$t("network.flashloansNo"),
                        //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                    });
                });


        },

        // 验证是否授权
        async exchangeallowance() {
            let _decimals = 18;
            let spender;
            let Address;
            Address = this.swap_Atoken
            spender = this.sitedata.approveswapV6_swap.address;
            await Plugens.allowancetoken(
                Address,
                0,
                this.addresscode,
                spender,
                _decimals
            ).then((result) => {
                console.log(result, '授权数量')
                if (result.data * 1 >= 10000) {
                    this.empowerbutton = false;
                } else {
                    this.empowerbutton = true
                }

            });
        },
        // 授权
        async Approve() {
            this.isloading = true
            let _decimals = 18;
            let Address;
            let spender;
            Address = this.swap_Atoken
            spender = this.sitedata.approveswapV6_swap.address;
            this.typeApprove = 1; // Approveing
            try {
                await Plugens.approveTokens(
                    Address, //
                    this.daiValue, // DAI number
                    this.addresscode,
                    spender,
                    _decimals
                ).then((res) => {
                    // console.log(res);
                    this.typeApprove = 0;
                    if (res.code == 0) {
                        this.typeApprove = 0;
                        this.$Notice.success({
                            title: this.$t("stake.AuthorSuccessful")
                            // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
                        });
                        this.isloading = false; // close loading
                        this.empowerbutton = false;

                    } else {
                        this.isloading = false;
                        if (res.code < 1) {
                            this.$Notice.warning({
                                title: res.error.message
                            });
                        } else {
                            // this.typeApprove = 1;
                            this.$Notice.warning({
                                title: this.$t("stake.AuthorFailed")
                                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                            });

                        }
                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("stake.AuthorFailed"),
                    // desc: this.$t("stake.AuthorFailed"),
                });
                // console.log(error);
            }
        },
        // 验证是否授权
        async exchangeallowance2() {
            let _decimals = 18;
            let spender;
            let Address;
            Address = this.swap_Atoken
            spender = this.sitedata.swapFalcon_token.address;
            await Plugens.allowancetoken(
                Address,
                0,
                this.addresscode,
                spender,
                _decimals
            ).then((result) => {
                console.log(result, '授权数量')
                if (result.data * 1 >= 10000) {
                    this.empowerbutton2 = false;
                } else {
                    this.empowerbutton2 = true
                }

            });
        },
        // 授权
        async Approve2() {
            this.isloading = true
            let _decimals = 18;
            let Address;
            let spender;
            Address = this.swap_Atoken
            spender = this.sitedata.swapFalcon_token.address;
            this.typeApprove = 1; // Approveing
            try {
                await Plugens.approveTokens(
                    Address, //
                    this.daiValue, // DAI number
                    this.addresscode,
                    spender,
                    _decimals
                ).then((res) => {
                    // console.log(res);
                    this.typeApprove = 0;
                    if (res.code == 0) {
                        this.typeApprove = 0;
                        this.$Notice.success({
                            title: this.$t("stake.AuthorSuccessful")
                            // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
                        });
                        this.isloading = false; // close loading
                        this.empowerbutton2 = false;

                    } else {
                        this.isloading = false;
                        if (res.code < 1) {
                            this.$Notice.warning({
                                title: res.error.message
                            });
                        } else {
                            // this.typeApprove = 1;
                            this.$Notice.warning({
                                title: this.$t("stake.AuthorFailed")
                                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                            });

                        }
                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("stake.AuthorFailed"),
                    // desc: this.$t("stake.AuthorFailed"),
                });
                // console.log(error);
            }
        },
        async swapExactTokens() {
            this.isloading = true;
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.swapFalcon_token.address;
            let amountAout = big(this.swap_Avalue)
                .times(10 ** this.swap_decimalsA)
                .toFixed();
            let amountBout = big(this.swap_Bvalue)
                .times(10 ** this.swap_decimalsB)
                .toFixed();
            var destAmount = this.subtractPercentage(amountBout)
            let currentTimestamp = Date.now();

            // 加上100秒（注意，时间戳是以毫秒为单位的，所以需要将秒转换为毫秒）
            let timestampPlus100Seconds = currentTimestamp + (100 * 1000);
            try {
                await swapFalconjs.swapExactTokens(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                    amountAout,
                    destAmount,
                    this.swap_Atoken,
                    this.swap_Btoken,
                    timestampPlus100Seconds
                ).then((res) => {
                    console.log(res, "swapExactTokens");
                    if (res.code == 0) {
                        this.isloading = false;

                        this.$Notice.success({
                            title: this.$t("network.flashloansOK"),
                        });
                    } else {
                        this.isloading = false;
                        this.$Notice.warning({
                            title: this.$t("network.flashloansNo"),
                        });
                    }
                });
            } catch (error) {
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("network.flashloansNo"),
                });
                //console.log(error);
            }
        },
        async swapExactTokens2() {
            this.isloading = true;
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.swapFalcon_token.address;
            let amountAout = big(this.swap_Avalue)
                .times(10 ** this.swap_decimalsA)
                .toFixed();
            let amountBout = big(this.swap_Bvalue)
                .times(10 ** this.swap_decimalsB)
                .toFixed();
            var destAmount = this.subtractPercentage(amountBout)
            let currentTimestamp = Date.now();

            // 加上100秒（注意，时间戳是以毫秒为单位的，所以需要将秒转换为毫秒）
            let timestampPlus100Seconds = currentTimestamp + (100 * 1000);
            try {
                await swapFalconjs.swapExactTokens2(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                    amountAout,
                    destAmount,
                    this.swap_Atoken,
                    this.sitedata.DAI_swap.address,
                    this.swap_Btoken,
                    timestampPlus100Seconds
                ).then((res) => {
                    console.log(res, "swapExactTokensForTokensSupportingFeeOnTransferTokens");
                    if (res.code == 0) {
                        this.isloading = false;

                        this.$Notice.success({
                            title: this.$t("network.flashloansOK"),
                        });
                    } else {
                        this.isloading = false;
                        this.$Notice.warning({
                            title: this.$t("network.flashloansNo"),
                        });
                    }
                });
            } catch (error) {
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("network.flashloansNo"),
                });
                //console.log(error);
            }
        },
        async FeeOnTransferTokens() {
            this.isloading = true;
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.swapFalcon_token.address;
            let amountAout = big(this.swap_Avalue)
                .times(10 ** this.swap_decimalsA)
                .toFixed();
            let amountBout = big(this.swap_Bvalue)
                .times(10 ** this.swap_decimalsB)
                .toFixed();
            var destAmount = this.subtractPercentage(amountBout)
            let currentTimestamp = Date.now();

            // 加上100秒（注意，时间戳是以毫秒为单位的，所以需要将秒转换为毫秒）
            let timestampPlus100Seconds = currentTimestamp + (100 * 1000);
            try {
                await swapFalconjs.FeeOnTransferTokens(
                    this.addresscode,
                    ContractAddress,
                    _decimals,
                    amountAout,
                    destAmount,
                    this.swap_Atoken,
                    this.sitedata.DAI_swap.address,
                    this.sitedata.wmatic_token.address,
                    timestampPlus100Seconds
                ).then((res) => {
                    console.log(res, "FeeOnTransferTokens");
                    if (res.code == 0) {
                        this.isloading = false;

                        this.$Notice.success({
                            title: this.$t("network.flashloansOK"),
                        });
                    } else {
                        this.isloading = false;
                        this.$Notice.warning({
                            title: this.$t("network.flashloansNo"),
                        });
                    }
                });
            } catch (error) {
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("network.flashloansNo"),
                });
                //console.log(error);
            }
        },
    },
    computed: {
        formattedDaiOwnMoney() {
            if (!this.swap_Bvalue) return "0.0000";

            const multipliedNumber = this.swap_Bvalue * 10000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 10000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        formdestUSD() {
            if (!this.destUSD) return "0.00";

            const multipliedNumber = this.destUSD * 100;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        forsrcUSD() {
            if (!this.srcUSD) return "0.00";

            const multipliedNumber = this.srcUSD * 100;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },

        forgasCostUSD() {
            if (!this.priceRoute.gasCostUSD) return "0.0000";

            const multipliedNumber = this.priceRoute.gasCostUSD * 10000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 10000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        formattedDaiOwnMoney2() {
            if (!this.swap_Bvalue / this.swap_Avalue) return "0.0000";

            const multipliedNumber = this.swap_Bvalue / this.swap_Avalue * 10000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 10000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        forswap_Bbalance() {
            if (!this.swap_Bbalance) return "0.00";

            const multipliedNumber = this.swap_Bbalance * 100;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        forswap_Abalance() {
            if (!this.swap_Abalance) return "0.00";

            const multipliedNumber = this.swap_Abalance * 100;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },
        forswap_Abalance2() {
            if (!this.swap_Abalance) return "0.0000";

            const multipliedNumber = this.swap_Abalance * 10000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 10000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);
            return value;
        },

        shortenedAddress: {
            get() {
                if (this.invAddress === "") {
                    return "0x";
                } else {
                    return (
                        this.invAddress.slice(0, 8) + "……" + this.invAddress.slice(-10)
                    );
                }
            },
            set(newVal) {
                this.invAddress = newVal;
            },
        },
        linkAddress2: {
            get() {
                return `https://polygonscan.com/address/${this.invAddress}`;
            },
        },
    },
};
</script>
<style scoped src="@/assets/css/EvSwap.css"></style>
<style lang="less" scoped></style>