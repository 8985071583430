import { render, staticRenderFns } from "./EvTeam.vue?vue&type=template&id=2729e0d8&scoped=true&"
import script from "./EvTeam.vue?vue&type=script&lang=js&"
export * from "./EvTeam.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/EvTeam.css?vue&type=style&index=0&id=2729e0d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_2c66e885b17cbecb4aa951ec806096ab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2729e0d8",
  null
  
)

export default component.exports